<template>
  <div class="eleven-page flex flex-d ai-c">
    <headers styleSize="min" :showTime="false"></headers>
    <img src="@/assets/speaial_activity_img/image/time_title.png" class="time-title" alt="">
    <div class="title-box flex jc-c ai-c">
      <img class="css-legtlogo" src="@/assets/speaial_activity_img/image/twelvelogo.png">
      <img class="css-rightlogo" src="@/assets/speaial_activity_img/image/twelvetitle.png">
    </div>
    <div class="code-box">
      弦镜（Synlen），是星图数据（Syntun）于2021年推出的数据搜索工具，依托于星图数据（Syntun）自主研发的先进技术，通过快速搜索行业信息、品牌信息、店铺信息、宝贝信息等功能为商家和专业研究人士提供全球电商数据，是各界人士必备的生意决策利器。
    </div>
    <div class="code-bottom en flex">
      <div class="left">
        <p class="title1 title-color">弦镜双十一大回馈</p>
        <p>- 购买单个平台VIP/SVIP权益享受<span class="hot">7</span>折优惠；</p>
        <p>- 购买2个平台VIP/SVIP权益享受<span class="hot">6</span>折优惠；</p>
        <p>- 购买3个及以上平台VIP/SVIP权益享受<span class="hot">5</span>折优惠。</p>
        <p class="title3">活动时间：2023年10月24日至2023年11月12日</p>
        <p class="title2 title-color">活动期间通过弦镜网站或弦镜APP购买VIP/SVIP权益</p>
      </div>
      <div class="right flex flex-d ai-c ">
        <div>网址：www.synlen.com</div>
        <div class="code-view flex flex-1">
          <div class="botRight flex flex-d ai-c jc-c" style="margin-right: 120px;">
            <img src="@/assets/speaial_activity_img/image/code-gzh.png" class="">
            <div class="botWz">扫码了解活动</div>
          </div>
          <div class="botRight flex flex-d ai-c jc-c">
            <img src="@/assets/speaial_activity_img/image/code_dy.png" class="">
            <div class="botWz">弦镜抖音二维码</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'

export default {
  components: {
    Headers,
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/zn.scss';
</style>